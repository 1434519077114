import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { ContentfulMetadata } from "../../types";

interface MetadataProps extends ContentfulMetadata {}

export const fragment = graphql`
  fragment Metadata on ContentfulMetadata {
    id
    internal {
      type
    }
    title
    description {
      description
    }
    ogTitle
    ogDescription {
      ogDescription
    }
    ogImage {
      fixed {
        src
      }
    }
    twitterTitle
    twitterDescription {
      twitterDescription
    }
    twitterImage {
      fixed {
        src
      }
    }
  }
`;

const Metadata: React.FC<MetadataProps> = ({
  title,
  description,
  ogTitle,
  ogDescription,
  ogImage,
  twitterTitle,
  twitterDescription,
  twitterImage,
}) => {
  return (
    <Helmet>
      <title>{title || "Wayne Lincoln - Developer"}</title>
      <meta
        name="description"
        content={
          description?.description ||
          "I am a developer who is passionate about learning new technologies across all levels of the stack. This website acts as an outlet for me to share my learnings, as well as provide ideation of solutions to the problems we face while trying to deliver products for the modern web and beyond."
        }
      />
      <meta
        property="og:title"
        content={ogTitle || "Wayne Lincoln - Developer"}
      />
      <meta
        property="og:description"
        content={
          ogDescription?.ogDescription ||
          "I am a developer who is passionate about learning new technologies across all levels of the stack. This website acts as an outlet for me to share my learnings, as well as provide ideation of solutions to the problems we face while trying to deliver products for the modern web and beyond."
        }
      />
      <meta
        property="og:image"
        content={
          ogImage
            ? ogImage.fixed.src
            : "https://waynelincoln.dev/wayne-lincoln.jpg"
        }
      />
      <meta property="twitter:card" content="summary" />
      <meta
        property="twitter:title"
        content={twitterTitle || "Wayne Lincoln - Developer"}
      />
      <meta
        property="twitter:description"
        content={
          twitterDescription?.twitterDescription ||
          "I am a developer who is passionate about learning new technologies across all levels of the stack. This website acts as an outlet for me to share my learnings, as well as provide ideation of solutions to the problems we face while trying to deliver products for the modern web and beyond."
        }
      />
      <meta
        property="twitter:image"
        content={
          twitterImage
            ? twitterImage.fixed.src
            : "https://waynelincoln.dev/wayne-lincoln.jpg"
        }
      />
    </Helmet>
  );
};

export default Metadata;
